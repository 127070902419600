.shop-container {
  display: flex;

  margin: 50px 0;
}
.res-side {
  display: none;
}
#sidebar-toggle-btn {
  font-size: 25px;
  text-align: right;
  margin-top: 50px;
  display: none;
}

#sidebar.show {
  display: block;
}

#sidebar {
  margin: 0px 20px;
  padding: 20px;
  border-right: 2px solid rgb(157, 157, 157);
  overflow-y: auto;
  background: url(../../images/Frame.png) no-repeat;
  background-position: bottom;
  background-size: contain;
}
#sidebar h4 {
  font-size: 30px;
  font-weight: bold;
}

#sidebar h6 {
  font-size: 22px;
}
#sidebar::-webkit-scrollbar {
  width: 8px;
}

/* Track */
#sidebar::-webkit-scrollbar-track {
  background: #f3f2e7;
}

/* Handle */
#sidebar::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #565656, #d0d0d0);
}

/* Handle on hover */
#sidebar::-webkit-scrollbar-thumb:hover {
  background: #717171;
}
.dropdown {
  padding: 10px;
  cursor: pointer;
}
.checkbox-container {
  margin: 20px 0;
}
.checkbox-container h6 {
  padding: 10px;
}
.checkbox-container .filter-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.price-slider-container {
  margin-top: 20px;
}
.price-slider {
  width: auto;
  height: 3px;
  background-color: rgb(106, 106, 106);
  margin-bottom: 10px;
}

.price-slider .thumb {
  width: 15px;
  height: 15px;
  cursor: pointer;
  background-color: rgb(91, 226, 86);
  top: -5px;
  border-radius: 50%;
}

.price-slider .thumb:focus {
  outline: none;
}

.range-values {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}

.dropdown ul {
  display: none;
  list-style: none;
  padding: 15px 15px 0 15px;
  transition: max-height 0.3s ease; /* Smooth transition */
}
.dropdown ul li {
  padding: 10px 0;
}
.dropdown ul.drop-show {
  display: block;
}
/* sort by filters */
.sort-container {
  display: block;
  margin-left: 20px;
}

.sort-btn {
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 20px;
  border: none;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sort-menu {
  position: absolute;
  display: block;
  height: auto;
  color: black;
  padding: 20px;
  background: white;
  border-radius: 10px;
  font-size: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sort-item {
  padding: 5px;
  cursor: pointer;
}

.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.search-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.search-input {
  width: 600px;
  height: 50px;
  padding: 20px;
  padding-right: 40px;
  border: none;
  outline: none;
  border-radius: 50px;
  background-color: #f3f2ec;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.search-bar-icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px; /* Adjust this value as needed */
}

.search-button {
  color: rgb(76, 76, 76);
  width: 40px;
  height: 50px;
  border-radius: 50%;
}

/* products */

.product-container {
  margin: auto;
}
#category-name {
  display: flex;
  justify-content: space-between;
}
#category-name h4 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.product-items {
  margin: 30px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  min-height: 250px;
}
.product-card {
  width: 300px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.product-image img {
  width: 100%;
  padding: 15px;
  height: 250px;
  border-radius: 30px;
  cursor: pointer;
}

.product-info {
  padding: 0px 20px;
}

.product-info h2 {
  margin-top: 0;
  font-size: 25px;
}

.product-description {
  color: #666;
  margin: 0;
}

.product-icons {
  color: rgb(241, 202, 28);
  margin-bottom: 10px;
}
.prod-price {
  display: flex;
  justify-content: space-between;
}
.prod-price p:first-child {
  color: rgb(100, 100, 100);
  text-decoration: line-through;
  margin-right: 10px;
  font-size: 17px;
}
.prod-price p {
  color: black;
  font-size: 20px;
}
.product-card-btn {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
}
.prod-added-to-cart {
  background-color: #80b20b;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
  cursor: pointer;
  width: 70%;
  transition: background-color 0.3s;
}

.add-to-cart {
  background-color: #ffc35b;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
  cursor: pointer;
  width: 70%;
  transition: background-color 0.3s;
}

.add-to-cart:hover {
  background-color: #e5950b;
}
.add-to-wishlist {
  background-color: #ffc35b;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 10px;
  width: auto;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-to-wishlist:hover {
  background-color: #e5950b;
}

/* pagination section */

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-desc {
  font-size: 25px;
}
.pagination-buttons button {
  background-color: #ffffff;
  border: none;
  outline: none;
  border-radius: 50%;
  padding: 5px 15px;
  margin-left: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1200px) {
  .product-items {
    grid-template-columns: repeat(2, 1fr);
  }
  .search {
    display: block;
  }
  .sort-container {
    margin: 20px auto;
  }
}

@media screen and (max-width: 880px) {
  #sidebar h6 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 850px) {
  #sidebar-toggle-btn,
  #handle-toggle-btn-2 {
    display: block;
  }

  #sidebar {
    display: none;
    z-index: 9999;
    width: 50%;
    height: 100%;
    overflow-y: auto;
    background: none;
    background-color: #ffffff;
    position: fixed;
    top: 90px;
    left: -5%;
    overflow-y: auto;
    transition: 0.4s ease;
    padding: 40px 50px 150px 40px;
  }

  #sidebar h4 {
    font-size: 20px;
  }
  .sort-container {
    margin: 50px 0;
  }
  .sort-menu {
    margin-top: 10px;
  }

  .view-type {
    display: flex;
    justify-content: space-between;
  }
  .res-side {
    display: block;
    text-align: center;
  }
  .res-side p {
    font-size: 15px;
  }
  .product-items {
    grid-template-columns: repeat(2, 1fr);
  }
  #category-name h4 {
    margin: auto;
  }
  .search-container {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 745px) {
  .product-container {
    margin: 0 auto;
  }

  .add-to-cart,
  .add-to-wishlist {
    padding: 10px 10px;
    font: 0.8em sans-serif;
  }
  .prod-added-to-cart {
    padding: 10px 10px;
    font: 0.8em sans-serif;
  }
}
@media only screen and (max-width: 700px) {
  .search-input {
    width: 500px;
  }
  .search-bar-icon {
    padding-right: 70px;
  }
  .product-card {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
}
@media only screen and (max-width: 635px) {
  #sidebar {
    width: 60%;
  }
  .product-items {
    grid-template-columns: repeat(2, 1fr);
  }
  .product-description {
    font-size: 15px;
  }
  .product-info h2 {
    font-size: 18px;
  }
  .product-icons {
    font-size: 12px;
  }
  .add-to-cart,
  .add-to-wishlist {
    padding: 10px 10px;
    font: 0.8em sans-serif;
  }
}
@media only screen and (max-width: 660px) {
  .search-bar-icon {
    padding-right: 60px;
  }
}
@media only screen and (max-width: 585px) {
  .search-input {
    width: 400px;
  }
  .product-items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 560px) {
  .product-items {
    grid-template-columns: repeat(2, 1fr);
  }
  .product-description {
    font-size: 15px;
  }
  .product-info h2 {
    font-size: 18px;
  }
  .product-icons {
    font-size: 12px;
  }
  .add-to-cart,
  .add-to-wishlist {
    padding: 5px 10px;
    font: 0.8em sans-serif;
  }
  .prod-added-to-cart {
    padding: 5px 10px;
    font: 0.8em sans-serif;
  }
}
@media only screen and (max-width: 510px) {
  .product-container {
    margin: auto;
  }
  .product-items {
    grid-template-columns: repeat(1, 1fr);
  }
  .search {
    display: block;
  }
  .search-input {
    width: 400px;
  }
  .search-bar-icon {
    padding-right: 20px;
  }
  .product-items {
    gap: 10px;
  }
  .add-to-cart,
  .add-to-wishlist {
    padding: 10px 20px;
    font: 0.8em sans-serif;
  }
  .pagination {
    display: block;
    margin: 20px;
  }
  .pagination-desc {
    font-size: 20px;

    margin-bottom: 20px;
  }

  .pagination-buttons button {
    background-color: #ffffff;
    border: none;
    outline: none;
    border-radius: 50%;
    padding: 5px 15px;
    margin-left: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  #sidebar-toggle-btn h5 {
    font-size: 25px;
  }
  .sort-btn {
    padding: 5px 5px;
    border-radius: 10px;
    font-size: 20px;
    border: none;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
@media only screen and (max-width: 460px) {
  #sidebar-toggle-btn h5 {
    font-size: 25px;
  }
  #sidebar {
    width: auto;
  }
  .search-input {
    width: 300px;
  }
  .search-bar-icon {
    padding-right: 40px;
  }
  .product-card {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 350px) {
  .search-input {
    width: auto;
  }
  .search-bar-icon {
    padding-right: 40px;
  }
}
