.contact-banner {
  background: url(../../images/CTA\ section.png) no-repeat;
  background-size: cover;
  z-index: 1;
  width: auto;
  height: 80vh;
}

.contact-banner h1 {
  color: white;
  font-style: italic;
  font-size: 50px;
  font-weight: bold;
  padding: 100px;
}

.pattern4-bg {
  mix-blend-mode: multiply;
  z-index: 0;
  position: absolute;
  height: auto;
  top: 90%;
  left: 5%;
  width: 90%;
}
.contact-form-container {
  position: relative;
  height: auto;
  width: 100%;
  background: url(../../images/10483_thumbnail\ 2.png) no-repeat right;
  background-size: contain;
}

.contact-form {
  background: rgba(251, 250, 240, 0.8);
  width: 80%;
  padding: 50px;
  margin: 80px auto;
  border-radius: 30px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.contact-form h2,
h5 {
  text-align: center;
  margin-bottom: 20px;
}

.contact-form h5 {
  margin-bottom: 40px;
}

#row1 {
  display: flex;
  width: 100%;
  gap: 10px;
}
.form-group {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.form-group input[type="text"] {
  width: 100%;
}
#grp1 {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.form-group input[type="text"],
.form-group textarea {
  padding: 15px;
  background: rgb(249, 248, 229);
  border: none;
  border-radius: 10px;
  box-shadow: inset rgba(50, 50, 93, 0.25) 0px 8px 5px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.form-group textarea {
  width: 100%;
  height: 200px;
  margin: 0;
  resize: none;
}

.form-group input[type="submit"] {
  background-color: #ffc35b;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  margin: auto;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.form-group input[type="submit"]:hover {
  background-color: #f3a31a;
}

.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus,
.form-group input[type="tel"]:focus,
.form-group textarea:focus {
  border: none;
  outline: none;
}

.form-group input[type="text"]::placeholder,
.form-group input[type="email"]::placeholder,
.form-group input[type="tel"]::placeholder,
.form-group textarea::placeholder {
  color: #000;
}

/* contact information */
.contact-info-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 50px;
}

.contact-info {
  background: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 400px;
  height: 200px;
  max-height: 300px;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add box shadow */
}
.con-icon {
  font-size: 70px;
  color: #4caf50;
  font-weight: bold;
}
#contact-text {
  margin-left: 30px;
  padding-top: 20px;
  width: 250px;
  font-size: 20px;
  word-wrap: break-word;
}
#contact-text p:first-child {
  color: #646464;
}

/* map container */

.map-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 30px;
  align-items: center;
  background: url(../../images/10483_thumbnail\ 1.png) no-repeat;
  background-size: contain;
}

.timings {
  position: relative;
  text-align: center;
  background-color: white;
  padding: 50px;
  width: 23%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add box shadow */
}
.timings button {
  background-color: #4caf50;
  color: white;
  padding: 10px 10px;
  width: 150px;
  border: none;
  font-size: 20px;
  border-radius: 50px;
  position: absolute;
  top: -8%;
}
.timing-btn {
  display: flex;
  justify-content: center;
}
.timings h4 {
  font: 18px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
}
.timings h5 {
  font: 18px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.map {
  border: 2px solid #4caf50;
  border-radius: 20px;
}
.map iframe {
  border-radius: 20px;
}

@media screen and (max-width: 1250px) {
  .pattern4-bg {
    top: 90%;
    width: 90%;
  }
}
@media only screen and (max-width: 1143px) {
  .timings {
    width: 500px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .pattern4-bg {
    top: 90%;
  }
  .timings {
    padding: 40px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 895px) {
  .map,
  iframe {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .pattern4-bg {
    display: none;
  }
  #grp1 {
    display: block;
  }
  #grp1 input:first-of-type {
    margin-bottom: 20px;
  }
  #row1 {
    display: block;
    width: 100%;
  }
  #row1 input {
    margin-bottom: 10px;
  }
  .contact-form {
    margin: 30px;
  }
  .map-container {
    padding: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .contact-form {
    margin: 50px auto;
    padding: 20px;
  }
  .contact-info {
    width: 100%;
  }
}
@media only screen and (max-width: 400px) {
  .timings h4,
  h5 {
    font-size: 15px;
  }
}
