.order-history-page {
  background: url(../../images/Group\ 133.png) no-repeat;
  background-color: #fffde6;
}
.order-history-page h2 {
  font-weight: bold;
  padding: 30px 0px 0 50px;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px #fff;
}
.modal-backdrop {
  background: none !important;
}
.modal-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border: none !important;
}
.order-history-container {
  backdrop-filter: blur(20px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  margin: 20px 50px 50px 50px;
  border-radius: 20px;
  width: auto;
}
.order-history-container h3 {
  font-family: Georgia, "Times New Roman", Times, serif;
  padding: 20px 80px;
  font-weight: bold;
}

.order-status {
  margin: 0 100px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.order-status img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #fff;
  margin-bottom: 10px;
}
.order-status h6 {
  font-size: 20px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.order-status p {
  font-size: 18px;
}
.order-history-product {
  margin: 0 50px 50px 50px;
  padding-bottom: 30px;
  display: flex;
  gap: 50px;
  border-bottom: 2px solid rgb(156, 156, 156);
}

.order-history-product img {
  width: 300px;
  height: 250px;
  padding-left: 30px;
  border-radius: 10px;
}

.order-description {
  display: flex;
  gap: 50px;
}
.order-history-product-desc {
  margin: 0px;
  padding: 0;
}
.order-history-product-desc h5 {
  font-size: 30px;
  word-wrap: break-word;
  width: auto;
  text-align: left;
  margin: 0 20px;
}
.order-history-product-price {
  white-space: nowrap;
  margin-right: 20px;
}
.order-history-product-price p {
  margin-right: 10px;
  font-size: 25px;
  white-space: nowrap; /* Prevent wrapping */
}

.order-details {
  display: block;
  margin-top: 30px;
  margin-left: 20px;
}
.order-detail1 {
  display: flex;
  gap: 50px;
}

.order-details p {
  line-height: 20px;
  font-size: 20px;
}

.return-order-btn {
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 10px 20px;
  background: rgb(229, 172, 28);
  color: white;
  margin-top: 20px;
  margin-right: 20px;
}

.cancel-order-btn {
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 5px 15px;
  background: rgb(229, 28, 28);
  color: white;
  margin-top: 20px;
  margin-right: 20px;
}

.confirm-cancel-btn {
  border: 2px solid rgb(67, 139, 39);
  outline: none;
  border-radius: 5px;
  padding: 3px 15px;
  background: none;
  color: rgb(67, 139, 39);
  margin-top: 20px;
  margin-right: 20px;
}

.cancel-btn {
  border: 2px solid rgb(229, 28, 28);
  outline: none;
  border-radius: 5px;
  padding: 3px 15px;
  background: none;
  color: rgb(229, 28, 28);
  margin-top: 20px;
  margin-right: 20px;
}

.view-order-address {
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 5px 15px;
  background: rgb(101, 167, 3);
  color: white;
  margin-right: 20px;
  margin-top: 20px;
}

.reason,
.comment {
  background-color: white;
  box-shadow: none;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid black;
  width: 100%;
  border-radius: 5px;
}
.comment {
  margin: 0;
}
.comment:focus,
.reason:focus {
  outline: 1px solid black;
  border: none;
}
.account-details {
  margin-top: 20px;
}
.refund-submit-btn {
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 5px 20px;
  background: rgb(229, 172, 28);
  color: white;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .order-history-product-desc h5 {
    font-size: 25px;
  }
}
@media screen and (max-width: 985px) {
  .order-description {
    display: flex;
    gap: 20px;
  }

  .order-history-product {
    margin: 0 20px 50px 20px;
    display: flex;
    gap: 20px;
  }
  .order-history-container h3 {
    padding: 20px 50px;
  }
  .order-status {
    margin: 0 50px;
  }
}
@media screen and (max-width: 800px) {
  .order-details p {
    line-height: 30px;
  }
  .view-order-address {
    margin-top: 0px;
  }
}
@media screen and (max-width: 780px) {
  .order-history-container {
    margin: 10px;
  }
  .order-history-product img {
    width: 200px;
    height: 150px;
    padding-left: 30px;
  }
  .order-history-product-price {
    margin-left: 20px;
  }
  .order-history-product-price p {
    font-size: 20px;
  }
  .order-description {
    display: block;
  }
}
@media screen and (max-width: 580px) {
  .order-history-product img {
    width: 300px;
    height: 200px;
    padding-left: 30px;
  }
  .order-history-product {
    display: block;
  }

  .order-history-product-desc {
    padding: 20px 20px 0 20px;
  }
  .order-history-product-price {
    padding: 10px 40px;
  }
}

@media screen and (max-width: 340px) {
  .order-history-product img {
    width: 250px;
    height: 150px;
  }
}
@media screen and (max-width: 300px) {
  .order-history-product img {
    width: 200px;
    height: 150px;
  }
}
