.signup-page {
  background: url(../../images/image\ 37.png) no-repeat;
  background-size: cover;
  background-position: center;
}
.signup-main-container {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: space-around;
}
.signup-main-container h2 {
  color: #efecca;
  line-height: 1.5;
  font-size: 50px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow */
}
.signup-form {
  height: auto;
  width: 500px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 30px 40px;
}

.signup-form * {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}
.signup-form h3 {
  font-size: 28px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.signup-form p {
  font-size: 14px;
  padding: 10px;
  text-align: center;
}
#fullname {
  display: flex;
  gap: 10px;
}
.error {
  font-size: 15px;
}

#fullname input[type="text"] {
  flex: 1;
}

.inputs input {
  width: 100%;
  margin: 10px 0px;
  padding: 10px;
  border-radius: 5px;
  background: #bab8b4;
  font-size: 16px;
  color: #000;
}
.inputs input::placeholder {
  color: black;
}

#checkbox-container {
  display: flex;
  align-items: center;
  margin: 0px;
}

#checkbox-container input[type="checkbox"] {
  margin-right: 10px;
  width: 25px;
  height: 20px;
  border: 2px solid white;
  border-radius: 3px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

#checkbox-container label {
  font-size: 16px;
  color: #ffffff;
}

.signup-btn {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 8px;
  margin: 10px 0px;
  background: #a7bb00;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);
}
.divider {
  flex: 1; /* Take up remaining space */
  margin: 10px 10px; /* Adjust margin as needed */
  text-align: center;
  position: relative;
}

.divider::before,
.divider::after {
  content: "";
  display: block;
  border-top: 1px solid #ffffff;
  width: calc(50% - 40px); /* Adjust width as needed */
  margin: 0 20px; /* Adjust margin as needed */
}

.divider::before {
  float: left;
}

.divider::after {
  float: right;
}
.divider span {
  position: absolute;
  top: -15px;
  left: 48%;
}

.signup-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
  cursor: pointer;
}
.login-link {
  display: flex;
  font: 1em "Poppins", sans-serif;

  justify-content: center;
  margin-top: 20px;
}

.forgot-password #forgot-link {
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
}

.forgot-password #forgot-link:hover {
  text-decoration: underline;
}

.password-container {
  display: flex;
  align-items: center;
}

.password-container button {
  float: right;
  margin-left: -30px;
  background: none;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 1165px) {
  .signup-main-container h2 {
    font-size: 40px;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 740px) {
  .signup-main-container {
    padding-bottom: 100px;
  }
  .signup-main-container h2 {
    font-size: 35px;
  }
  .signup-form {
    width: 420px;
    margin: auto;
  }
}
@media only screen and (max-width: 740px) {
  .signup-main-container {
    display: block;
    height: auto;
    text-align: center;
  }
}
@media only screen and (max-width: 430px) {
  .signup-main-container h2 {
    font-size: 30px;
  }
  .signup-form {
    width: 350px;
    margin: auto;
  }
}
@media only screen and (max-width: 370px) {
  .signup-form {
    width: 300px;
    margin: auto;
  }
  #fullname {
    display: block;
  }
  .signup-form h3 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 310px) {
  .signup-form {
    width: 250px;
    padding: 10px;
    margin: auto;
  }
  #fullname {
    display: block;
  }
  .signup-form h3 {
    font-size: 20px;
  }
}
