.cart-products-container {
  background: #f5f5f5;
  margin: 30px;
  border-radius: 20px;
  width: auto;
  height: auto;
}
.cart-products-container h4 {
  padding: 20px;
  font-weight: bold;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.cart-product {
  padding: 0 0px 50px 0px;
  display: flex;
  justify-content: space-around;
}
.cart-product img {
  width: 300px;
  height: 250px;
  padding-left: 30px;
  border-radius: 20px;
}
.cart-desc {
  margin: 0px 0 0 30px;
  padding: 0;
}
.cart-row {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.cart-row h5 {
  font-size: 30px;
  word-wrap: break-word;
  width: 900px;
  text-align: left;
  margin: 0;
  padding: 0;
}
.cart-row .cart-product-price {
  white-space: nowrap;
  margin: 0 20px;
}
.cart-row .cart-product-price p {
  font-size: 25px;
}
.cart-row .cart-product-price .cart-product-rating div:first-of-type {
  color: #ffc55b;
}
.cart-buttons {
  display: block;
}

.cart-buttons .size-btn {
  font-size: 0.8em;
  border-radius: 20px;
  width: 100px;
  height: 30px;
  background: none;
}
.cart-quantity-btn {
  border: 2px solid black;
  font-size: 0.8em;
  display: flex;
  justify-content: space-around;
  border-radius: 20px;
  width: 100px;
  margin-bottom: 20px;
  padding: 2px;
}
.cart-quantity-btn button {
  border: none;
  background: none;
}
.cart-buttons p {
  margin: 8px 0;
  font-size: 18px;
}

#cart-product-view {
  border: none;
  background: #898989;
  padding: 5px 20px;
  border-radius: 8px;
  color: white;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.options span {
  margin-right: 3rem;
  font-size: 18px;
  cursor: pointer;
}

.options {
  font-size: 18px;
  margin-top: 5px;
}

.price-details-container {
  width: 600px;
  background: #f5f5f5;
  margin: 30px auto;
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.price-details {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 25px;
}
.price-detail-btn {
  display: flex;
  justify-content: center;
}

.price-detail-btn button {
  background: #ffc55b;
  border: none;
  width: 100%;
  height: 50px;
  border-radius: 50px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.empty-cart-btn {
  background: #ffc55b;
  border: none;
  border-radius: 5px;
  padding: 5px 50px;
  text-transform: uppercase;
}
@media screen and (max-width: 1500px) {
  .cart-desc h5 {
    width: auto;
  }
  .cart-row {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 1200px) {
  .cart-products-container {
    margin: 10px;
  }

  .cart-desc {
    padding: 10px;
  }
  .cart-row .cart-product-price {
    text-align: left;
  }

  .cart-desc h5 span {
    font-size: 20px;
  }
  .cart-product-price {
    padding-left: 30px;
  }
  .cart-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .quantity-btn {
    padding: 5px 5px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .options {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1155px) {
  .cart-row {
    display: block;
  }
  .cart-row .cart-product-price {
    margin: 0;
    padding: 0;
  }
}
@media screen and (max-width: 855px) {
  .cart-products-container {
    margin: 10px;
  }
  .cart-product img {
    width: 150px;
    height: 150px;
  }
  .cart-desc {
    padding: 0 10px;
  }
  .cart-row {
    display: block;
  }
  .cart-row .cart-product-price {
    text-align: left;
  }
  .cart-row .cart-product-price p {
    font-size: 18px;
  }

  .cart-desc h5 {
    font-size: 25px;
    width: auto;
  }
  .cart-desc h5 span {
    font-size: 16px;
  }
  .cart-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .quantity-btn {
    padding: 5px 5px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .options span {
    text-align: left;
  }
}
@media screen and (max-width: 650px) {
  .price-details-container {
    width: auto;
  }
}
@media screen and (max-width: 450px) {
  .cart-product {
    display: block;
  }
  .cart-product img {
    width: 250px;
  }
  .cart-desc {
    padding: 20px 30px;
  }
}
@media screen and (max-width: 435px) {
  .quantity-btn {
    width: auto;
  }

  .cart-desc {
    margin: 0px 0;
  }
  .size-btn {
    width: auto;
  }
}
@media screen and (max-width: 380px) {
  .cart-product {
    display: block;
  }
  .cart-product img {
    width: 250px;
  }
  .cart-desc {
    padding: 20px;
  }
}
@media screen and (max-width: 285px) {
  .cart-product img {
    width: auto;
  }
}
