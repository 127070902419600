.product-detail-container {
  background-color: white;
  display: flex;
  padding: 50px;
}
.product-image-container {
  display: flex;
  justify-content: space-between;
}
.product-image-gallery {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 20px;
  height: 450px;
  width: 120px;
  overflow-y: scroll; /* Enable vertical scrolling */
  padding-right: 10px;
}
.product-image-gallery img {
  cursor: pointer;
  border-radius: 10px;
  height: 100px !important;
  flex-shrink: 0;
  object-fit: cover;
  width: 100px;
}
.product-single-image img {
  width: 500px;
  height: 450px;
  border-radius: 20px;
}

/* Webkit-based browsers */
.product-image-gallery::-webkit-scrollbar {
  width: 10px;
}

.product-image-gallery::-webkit-scrollbar-track {
  background: transparent;
}

.product-image-gallery::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #4d4d4d, #aeaeae); /* Gradient color */
  border-radius: 5px;
}

.product-details {
  padding: 10px 50px;
}

.product-rating {
  color: #ffc55b;
}
.product-price span:first-of-type {
  color: black;
  font-size: 25px;
  margin-right: 20px;
}
.product-price span:last-of-type {
  color: rgb(110, 110, 110);
  text-decoration: line-through;
}

.quantity-btn {
  border: 2px solid black;
  font-size: 0.8em;
  display: flex;
  justify-content: space-around;
  border-radius: 20px;
  width: 100px;
  padding: 5px 0;
  margin-bottom: 20px;
}
.quantity-btn button {
  border: none;
  background: none;
}
.size-btn {
  font-size: 0.8em;
  border-radius: 20px;
  width: 100px;
  height: 30px;
  background: none;
  margin-bottom: 20px;
  margin-right: 20px;
}
.active {
  background-color: rgb(189, 189, 189);
}
.product-buttons {
  display: flex;
}
.buy-now {
  background: #ffc55b;
  border: none;
  width: 150px;
  border-radius: 50px;
  padding: 8px 10px;
  margin-right: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.prod-cart-btn {
  background: #ffc55b;
  border: none;
  width: 150px;
  border-radius: 50px;
  padding: 8px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.prod-added-cart-btn {
  background: #80b20b;
  border: none;
  width: 150px;
  border-radius: 50px;
  padding: 8px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.share-btn {
  margin-top: 20px;
  background: #ffc55b;
  border: none;
  width: 150px;
  border-radius: 50px;
  padding: 8px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* description and reviews section */
.description-reviews-container {
  background: url("../../images/leaves.png") no-repeat;
}
.description-reviews-container .buttons {
  display: flex;
  justify-content: center;
  margin: 50px auto;
}
.buttons button {
  background: #ffc55b;
  border: none;
  width: 150px;
  border-radius: 50px;
  padding: 8px 10px;
  margin-right: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.description-btn.active,
.reviews-btn.active {
  background-color: #b1b1b1; /* Grey background color */
}

.description-box {
  background-color: white;
  padding: 40px;
  margin: auto;
  width: 900px;
  margin-bottom: 50px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.desc-table {
  margin: 50px auto;
}
.desc-table table {
  border-collapse: collapse;
  margin: auto;
}

.desc-table table td {
  padding: 8px 50px;
}

.desc-table .bold {
  font-weight: bold;
}

.desc-table table,
td {
  border: none;
}
.desc-list {
  margin: 0 50px;
}
.desc-list p {
  font-size: 20px;
  font-weight: bold;
}
.desc-list ul li {
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* reviews  form*/
.review-form-container {
  width: 100%;
  height: 900px;
  background: url(../../images/leaves.png);
  padding: 150px 0;
}
.review-form {
  display: flex;
  justify-content: space-around;
  padding: 80px;
  z-index: 2;
  background: rgba(239, 238, 221, 0.7);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.review-form img {
  width: 600px;
  height: 500px;
}

.review-form form {
  text-align: center;
  font-weight: bold;
  padding: 0 50px;
}
.review-form form input,
textarea {
  width: 90%;
  padding: 15px;
  background: rgb(249, 248, 229);
  border: none;
  border-radius: 10px;
  margin: 10px;
  box-shadow: inset rgba(50, 50, 93, 0.25) 0px 8px 5px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.review-form form textarea {
  height: 120px;
}
.review-form form input:focus,
textarea:focus {
  outline: none;
  border: none;
}
.review-form form input[type="submit"] {
  width: 50%;
  background: #ffc55b;
  border-radius: 50px;
}

.rating-stars {
  display: flex;
  margin-left: 30px;
  color: #ecb912;
}

@media screen and (max-width: 1170px) {
  .product-single-image img {
    width: 400px;
  }
  .product-details {
    padding: 10px 20px;
  }
  .buy-now {
    margin-right: 5px;
  }
  .buy-now,
  .prod-cart-btn {
    width: auto;
    padding: 5px 20px;
  }
  .quantity-btn {
    margin-bottom: 8px;
  }
  .size-btn {
    margin-right: 5px;
  }

  .review-form-container {
    width: auto;
    height: auto;
  }
  .review-form img {
    width: 450px;
    height: 500px;
  }
  .review-form {
    padding: 20px;
    height: auto;
  }
}
@media screen and (max-width: 1058px) {
  .review-form img {
    width: 400px;
    height: 450px;
  }
}
@media screen and (max-width: 945px) {
  .product-detail-container {
    display: block;
  }
  .product-single-image img {
    width: 100%;
  }
}
@media screen and (max-width: 913px) {
  .product-image-gallery {
    margin-right: 10px;
  }

  .description-box {
    width: auto;
  }
}
@media screen and (max-width: 890px) {
  .review-form form {
    padding: 10px;
  }
}
@media screen and (max-width: 830px) {
  .product-image-gallery {
    margin-right: 20px;
    width: 150px;
  }
  .product-image-container {
    margin-bottom: 20px;
  }
  .buy-now {
    margin-right: 20px;
  }
  .quantity-btn {
    margin-bottom: 20px;
  }
  .size-btn {
    margin-right: 10px;
  }
  .buy-now,
  .prod-cart-btn {
    padding: 8px 30px;
  }
  .dots {
    display: block;
    text-align: center;
  }
}
@media screen and (max-width: 750px) {
  .review-form img {
    width: 350px;
    height: 400px;
    padding-top: 20px;
  }
  .story {
    width: 400px;
    height: auto;
  }
}
@media screen and (max-width: 715px) {
  .product-image-gallery {
    padding-right: 20px;
    width: 170px;
  }
}
@media screen and (max-width: 620px) {
  .story {
    height: auto;
  }
  .product-image-gallery {
    flex-direction: row;

    padding-bottom: 10px;
    width: auto;
    height: auto;
    margin-right: 0;
    padding-right: 0;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .product-image-container {
    display: flex;
    flex-direction: column-reverse; /* Reverse the order of elements */
    margin-bottom: 0;
  }

  /* Webkit-based browsers */
  .product-image-gallery::-webkit-scrollbar {
    width: auto;
    height: 12px;
  }
}
@media screen and (max-width: 660px) {
  .desc-table table td {
    padding: 8px 50px;
  }

  .desc-list {
    margin: 0 20px;
  }

  .review-form {
    display: block;
  }
  .review-form img {
    width: 100%;
    height: 400px;
    padding-top: 20px;
  }
}
@media screen and (max-width: 550px) {
  .product-detail-container {
    padding: 10px;
  }
  .product-image-container {
    padding: 0px;
  }
  .product-image-gallery {
    margin-right: 10px;
    gap: 10px;
  }
  .product-single-image img {
    margin: 0;
    height: 400px;
  }
  .product-image-gallery img {
    width: 90px;
    height: 90px;
  }
  td {
    padding: 8px 20px;
  }
}

@media screen and (max-width: 440px) {
  .story {
    width: 300px;
    height: auto;
    border-radius: 20px;
  }
}
@media screen and (max-width: 400px) {
  .product-image-gallery img {
    width: 80px;
    height: 80px;
  }
  .product-single-image img {
    margin: 0;
    height: 350px;
  }
}

@media screen and (max-width: 425px) {
  .desc-table table td {
    padding: 8px 20px;
  }

  .desc-list {
    margin: 0 0px;
  }
}
@media screen and (max-width: 372px) {
  .product-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .desc-table table td {
    padding: 8px 10px;
  }
  .buy-now {
    width: 120px;
    border-radius: 50px;
    padding: 8px 10px;
    margin-right: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .prod-cart-btn {
    background: #ffc55b;
    border: none;
    width: 120px;
    border-radius: 50px;
    padding: 8px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}
