.profile-page {
  background: url(../../images/Group\ 133.png) no-repeat;
  background-position: center;
}

.profile-form-container {
  margin: 50px 150px;
  padding: 50px;
  border-radius: 20px;
  backdrop-filter: blur(12px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}

.profile-form-container h3 {
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.user-info {
  margin-bottom: 50px;
}

.user-info h5 {
  text-align: left;
}

.profile-form-container input {
  border: none;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.user-email-mob {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.save-button {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.save-button button {
  background: #ffc55b;
  border: none;
  width: 150px;
  border-radius: 50px;
  padding: 8px 10px;
  margin-right: 20px;
  font-size: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.user-address h3 {
  margin-bottom: 20px;
}
.address-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
}

.add-address-container {
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  height: 340px; /* Set a fixed height for the container */
  width: 300px;
  padding: 20px;
}
.add-address {
  border-radius: 10px;
  border: 1px dashed rgb(148, 148, 148);
  font-size: 20px;
  padding: 80px 80px;
}
.add-address-btn {
  background: none;
  border: none;
  color: black;
  padding: 10px;
}

.address-item {
  background: white;
  border-radius: 10px;
  width: 300px;
  height: auto;
  padding: 30px 20px;
}

.form-body {
  padding: 0;
  margin: 0;
}

.delivery-address-form {
  padding: 0 0px;
}
.delivery-address-form #save-btn {
  display: flex;
  justify-content: center;
}
.delivery-address-form button {
  padding: 5px 30px;
  border: none;
  font-size: 20px;
  background-color: #ffc35b;
  border-radius: 8px;
}
.delivery-address-form input {
  width: 100%;
  background: #fcfaea;
  padding: 18px 12px;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-bottom: 15px;

  box-shadow: inset 0 2px 2px rgba(87, 87, 87, 0.5);
}
.error {
  color: red;
  font-size: 15px;
}
.address-item div {
  border-top: 2px solid black;
  padding: 10px 0 0 20px;
}

.address-item button {
  background: none;
  color: rgb(79, 79, 79);
  border: none;
}

#save-changes-btn {
  background: #86bb00;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
}
.add-address-form form {
  padding: 50px;
}

.modal-footer {
  display: flex;
  justify-content: space-around;
}

.address-form input {
  width: 100%;
  background: #fcfaea;
  padding: 15px 12px;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: inset 0 2px 2px rgba(87, 87, 87, 0.5);
}

#dlt-add-yes-btn {
  background: #ffc55b;
  border: none;
  border-radius: 50px;
  color: rgb(0, 0, 0);
  padding: 5px 30px;
}
#dlt-add-no-btn {
  background: #ffffff;
  border: 1px solid black;
  border-radius: 50px;
  color: rgb(0, 0, 0);
  padding: 3px 30px;
}

@media screen and (max-width: 1100px) {
  .profile-form-container {
    margin: 50px 50px;
  }
}
@media screen and (max-width: 680px) {
  .profile-form-container {
    margin: 50px 50px;
  }
  .user-fullname {
    display: block;
    margin: 0;
  }
  .user-fullname input {
    width: 100%;
    margin-bottom: 20px;
  }
  .user-email-mob {
    display: block;
  }
  .user-email-mob input {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 460px) {
  .profile-form-container {
    margin: 20px 20px;
    padding: 30px;
  }
}
@media screen and (max-width: 400px) {
  .add-address {
    padding: 85px 20px;
  }
}
