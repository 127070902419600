@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap&family=Outfit:wght@100..900");

html {
  scroll-behavior: smooth;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background: #efecca;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.toast-message {
  background: rgb(0, 0, 0);
  color: #fff;
  font-size: 20px;
  width: 34vw;
  padding: 30px 20px;
}

/*------------------------------------ navbar -------------------------------------------*/
nav {
  width: 100%;
  height: 75px;
  background-color: #fffcf7;
  padding: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.logo img {
  width: 180px;
  height: 70px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

nav ul {
  margin: 0;
  padding: 0;
}

nav ul li {
  list-style: none;
  display: inline-block;
}
nav ul li .link {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  padding: 0px 30px;
}
nav ul li .link:hover {
  color: #707070;
}
nav ul .active {
  color: #707070;
}

.nav-icons a {
  color: #000000;
  margin: 0 10px;
}

.nav-icons a:hover {
  color: #707070;
}
.nav-icons .active {
  color: #707070;
}

nav .btn {
  background: none;
  border: 2px solid #000000;
  font-weight: 600;
  color: #000000;
  border-radius: 8px;
  padding: 7px 15px;
  margin-left: 20px;
  margin-right: 20px;
}

.btn:hover {
  background-color: #000;
  color: white;
}

.checkbtn {
  color: #fff;
  font-size: 25px;
  cursor: pointer;
  float: right;
  display: none;
  height: 0px;
}
#check {
  display: none;
}
.responsive {
  display: none;
}

nav ul.show {
  display: block;
}

.search-form {
  display: flex;
  position: absolute;
  top: 13%;
  right: 2rem;
  width: 600px;
  border-radius: 10px;
  z-index: 999;
  overflow: hidden;
}

#search-box {
  border: none;
  width: 100%;
  padding: 15px;
  padding-right: 40px;
  border: none;
  outline: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #fffbd5;
  box-shadow: 20px 20px 10px 0px rgba(0, 0, 0, 0.5);
}
.search-form button {
  border: none;
  padding: 15px;
  padding-right: 20px;
  border: none;
  outline: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #fffbd5;
}

#search-box::placeholder {
  color: #000;
}

/* user menu */
.user-menu {
  position: absolute;
  top: 55px; /* Adjust as needed to position the menu below the user icon */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 150px;
  padding: 10px 0px;
  display: none;
  z-index: 3;
}
.nav-icons .user-icon:hover + .user-menu,
.user-menu:hover {
  display: block;
}
.user-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-menu ul li {
  margin-bottom: 5px;
  display: block;
  text-align: center;
}

.user-menu ul li #user-link {
  text-decoration: none;
  color: #333;
  display: block;
  padding: 5px 0;
}

.user-menu ul li #user-link:hover {
  background-color: #f0f0f0;
}
/* responsive user menu */

.res-menu {
  display: block;
}

/* CSS for Responsive Menu */
.res-menu {
  display: none;
  position: absolute;
  top: 100%;
  padding: 10px;
  z-index: 999;
}

/* Style for each item in the responsive menu */
.res-menu li {
  list-style: none;
  margin: 5px 20px;
}

/* Style for links in the responsive menu */
.res-menu a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

@media screen and (max-width: 1155px) {
  nav ul li .link {
    padding: 0 20px;
  }
}
@media screen and (max-width: 1024px) {
  nav {
    padding: 15px;
    height: 90px;
    line-height: 60px;
    display: block;
  }
  .nav-search-bar {
    display: none;
  }
  .checkbtn {
    display: block;
    color: #000;
  }
  nav .logo p {
    font-size: 30px;
    float: left;
  }
  nav ul {
    z-index: 9999;
    width: 60%;
    height: 100%;
    background-color: #fffcf7;
    position: fixed;
    top: 90px;
    right: -100%;
    transition: 0.4s ease;
    padding: 20px 20px 40px 20px;
  }
  nav ul li {
    display: block;
    text-align: left;
    line-height: 10px;
    padding: 20px 0;
    border-bottom: 2px solid rgb(207, 207, 207);
    font-size: 20px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
  }
  .responsive,
  #res-cart-badge {
    display: block;
  }
  .res-menu {
    display: block;
    z-index: 999;
    left: 0%;
    position: relative;
  }
  .cart-icon,
  .btn,
  .user-icon,
  #cart-badge {
    display: none;
  }
  nav .search-icon {
    position: absolute;
    z-index: 9000;
    right: 3%;
    top: 2.5%;
    margin-right: 40px;
  }
  nav .search-icon:hover {
    color: #707070;
  }
  #check:checked ~ ul {
    right: 0%;
  }
}

@media screen and (max-width: 680px) {
  .search-form {
    width: 400px;
  }
}
@media screen and (max-width: 500px) {
  .search-form {
    width: auto;
  }
  nav .logo img {
    width: 120px;
    height: 50px;
  }
}
@media screen and (max-width: 400px) {
  nav ul {
    width: 70%;
  }

  .link {
    font-size: 15px;
  }
  nav ul {
    padding: 5px;
  }
}
@media screen and (max-width: 275px) {
  nav .logo p {
    font-size: 20px;
  }
  .nav-icons a {
    margin: 0 25px 0 0;
  }
}

/*--------------------------------------------- home page carousel----------------------------------------- */
.carousel {
  height: 80vh;
}

.carousel img {
  height: 80vh;
}

.carousel-heading {
  position: absolute;
  top: 20%;
  right: 8%;
  font-weight: 600;
  color: white;
  font-family: "Outfit", sans-serif;
  font-style: italic;
  font-size: 45px;
  line-height: 70px;
  text-align: right;
}

@media (max-width: 767px) {
  .carousel-heading {
    font-size: 35px;
    line-height: 50px;
  }
}

/* ----------------------------------headings ----------------------------------------------*/
.headings {
  text-align: center;
}
.headings h1 {
  font: bolder 3em "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
}
.headings img {
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .headings h1 {
    font-size: 2em;
  }
  .headings img {
    width: 90%;
  }
}

/*---------------------------------- category section-------------------------------------- */
.category-section {
  background: url(./images/bg.png);
  background-position: center;
  background-repeat: repeat;
  padding-bottom: 50px;
}

.main-grid-container {
  display: flex;
  justify-content: center;
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 100px;
}

.cat-item img {
  width: 300px;
  height: 250px;
  border-radius: 10px;
  cursor: pointer;
  transition: filter 0.3s ease; /* Add transition for width and filter */
}

.cat-item:hover img {
  filter: brightness(70%); /* Reduce brightness on hover */
}

.cat-item h4 {
  font: bold 1em Arial, Helvetica, sans-serif;
  text-align: center;
  padding-top: 20px;
  font-size: 25px;
  cursor: pointer;
}

.category-section .viewall {
  background-color: #fbc64a;
  border: none;
  color: white;
  border-radius: 50px;
  font-size: 20px;
  width: 130px;
  padding: 10px 10px;
  margin-top: 20px;
  margin-bottom: 40px;
}
.category-section .viewall:hover {
  background: #d4a538;
}
@media only screen and (max-width: 1300px) {
  .category-grid {
    gap: 80px;
  }
}
@media only screen and (max-width: 1200px) {
  .main-grid-container {
    width: 100%;
  }

  .category-grid {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Adjust for 2 columns on smaller screens */
    gap: 20px; /* Reduce gap between items */
  }

  .cat-item img {
    width: 90%; /* Make images fill the container */
  }
}
/* Media Queries */
@media only screen and (max-width: 768px) {
  .main-grid-container {
    width: 100%;
  }

  .category-grid {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Adjust for 2 columns on smaller screens */
    gap: 0px; /* Reduce gap between items */
    margin-left: 20px;
  }

  .cat-item img {
    width: 90%; /* Make images fill the container */
  }
  .cat-item h4 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .category-grid {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Adjust for 1 column on even smaller screens */
    font-size: 18px;
  }
  .cat-item img {
    width: 90%; /* Make images fill the container */
    height: 180px;
  }
}

@media only screen and (max-width: 410px) {
  .category-grid {
    grid-template-columns: repeat(
      1,
      1fr
    ); /* Adjust for 1 column on even smaller screens */
    font-size: 18px;
  }
  .cat-item img {
    width: 90%; /* Make images fill the container */
    height: 180px;
  }
}

/* ---------------------------------------journey container--------------------------------------------- */
.journey-container {
  background: url(./images/Rectangle\ 121.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
}

#part1 {
  display: flex;
  justify-content: space-around;
}

#part1 img {
  margin: 50px;
}
.journey-text {
  color: #fff;
  margin: 50px;
}
.journey-text h3 {
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 20px;
}
.journey-text p {
  width: 700px;
  font-size: 25px;
}

#part2 {
  display: flex;
  justify-content: space-around;
}
#part2 img {
  margin: 50px;
}

.quote-section {
  background: url("./images/Rectangle\ 138.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 450px;
}

.quote {
  color: #fff;
  width: 850px;
  font: italic 40px bold;
  padding: 70px;
  line-height: 1.5;
}
@media only screen and (max-width: 1300px) {
  .journey-text p {
    width: auto;
  }
}
/* Media queries for journey container */
@media only screen and (max-width: 1200px) {
  .journey-text p {
    width: auto;
    font-size: 1em;
  }

  #part1 img,
  #part2 img {
    width: 350px;
    height: 300px;
  }
  .quote-section {
    background-position: center;
  }
  .quote {
    width: auto;
    padding: 30px;
    width: 500px;
  }
}
@media only screen and (max-width: 935px) {
  .journey-text {
    margin: 20px;
  }
}
@media only screen and (max-width: 768px) {
  #part1 img,
  #part2 img {
    width: 250px;
    height: auto;
  }

  .journey-container h3 {
    text-align: center;
  }
  .journey-text p {
    text-align: center;
    width: auto;
    font-size: 1em;
  }

  #journey-img {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 710px) {
  #part1,
  #part2 {
    display: block;
  }
  #part1 img,
  #part2 img {
    width: auto;
  }
  .quote {
    font-size: 30px;
    padding: 50px;
  }
}

@media only screen and (max-width: 555px) {
  #part1 {
    display: block;
  }
  #part1 img,
  #part2 img {
    width: 80%;
    margin: 20px;
  }

  .journey-text {
    margin: 20px;
  }
  .journey-text p {
    width: auto;
    font-size: 01em;
  }
  #part2 {
    display: block;
  }

  .quote {
    width: 350px;
    font-size: 30px;
    padding-top: 50px;
  }
}
@media only screen and (max-width: 355px) {
  .quote {
    width: auto;
    font-size: 30px;
    padding-top: 20px;
  }
  .headings h1 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 325px) {
  .quote {
    font-size: 25px;
    padding-top: 20px;
  }
}

/* --------------------------------------------------footer section------------------------------------------------------ */

.footer-section {
  background: url(./images/Rectangle\ 14.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  margin: 0;
  text-align: center;
  padding: 20px;
}

.footer-section ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  align-items: center;
  margin-top: 20px;
  margin: 0;
  padding: 0;
  text-align: center;
}
.footer-section ul li #footer-link {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  margin-right: 20px;
}

.footer-section ul li #footer-link:hover {
  text-decoration: underline;
}

.footer-section .social-icons {
  cursor: pointer;
  text-align: center;
}

.social-icons img {
  padding: 15px 10px;
}

.info {
  color: white;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
}

.info span {
  padding: 10px 10px 0px 10px;
  text-align: center;
}
.info p {
  text-align: center;
}
@media only screen and (max-width: 340px) {
  .footer-section ul li #footer-link {
    list-style: none;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .footer-section {
    height: auto;
  }
  .footer-section ul {
    margin-top: 0px;
  }
  .footer-section ul li #footer-link {
    font-size: 15px;
  }

  .social-icons img {
    padding: 0px 10px;
  }

  .info {
    display: block;
    text-align: center;
  }
  .info span {
    padding: 0px;
    font-size: 13px;
    margin: 0;
    text-align: center !important;
  }
  .info span p {
    margin: 0;
    text-align: center !important;
  }
}

@media only screen and (max-width: 768px) {
  .footer-section ul {
    margin-top: 0px;
  }
  .footer-section ul li #footer-link {
    font-size: 15px;
  }

  .social-icons img {
    padding: 0px 10px;
  }

  .info span p {
    padding: 20px 0;
    font-size: 13px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer-section {
    width: 100%;
  }
  .footer-section ul {
    margin-top: 10px;
  }
  .footer-section ul li #footer-link {
    font-size: 15px;
  }

  .social-icons img {
    padding: 10px 10px;
  }

  .info span p {
    padding: 0px 0px;
    font-size: 15px;
  }
}

/* ScrollToTopButton.css */

.scroll-to-top {
  position: fixed;
  bottom: 100px;
  right: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #d9a22b;
  color: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  transform: translateY(-100%);
  z-index: 3000;
}

.scroll-to-top.show {
  opacity: 1;
  transform: translateY(0);
}

.scroll-to-top:hover {
  background-color: #a47717;
}

.scroll-to-top svg {
  width: 24px;
  height: 24px;
}
@media only screen and (max-width: 575px) {
  .scroll-to-top {
    right: 10px;
    bottom: 50px;
  }
}
