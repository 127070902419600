.img-slide img {
  height: 300px;
  width: auto;
  border-radius: 20px;
  margin: 20px;
}
.swiper-button-prev,
.swiper-button-next {
  padding: 30px 30px;
}

.swiper-slide {
  width: 400px !important;
}
.cart,
.wishlist {
  background: #ffc358;
  border: none;
  border-radius: 10px;
  height: 60px;
  padding: 10px 20px;
  margin-top: 10px;
}
.cart {
  margin-right: 10px;
  width: 70%;
}
.added-cart {
  background: #80b20b;
  border: none;
  color: rgb(0, 0, 0);
  border-radius: 10px;
  height: 60px;
  padding: 10px 20px;
  margin-top: 10px;
  width: 70%;
  margin-right: 10px;
}
.wishlist {
  width: 80px;
  color: white;
}

.cart:hover,
.wishlist:hover {
  background: #e8ab42;
}
.card-content {
  padding-top: 0px;
  padding-left: 20px;
  font-size: 20px;
}
.card-content h5 {
  font-size: 25px;
  text-align: left;
  font-weight: bold;
}

.card {
  border-radius: 20px;
  cursor: pointer;
}
@media screen and (max-width: 440px) {
  .swiper-slide {
    width: 350px !important;
    height: 500px;
  }
  .img-slide img {
    height: 200px;
    width: auto;
    border-radius: 20px;
    margin: 20px;
  }
}
