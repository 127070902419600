.terms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  max-width: 1000px;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

h2 {
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 25px;
}

p,
ul {
  text-align: left;
  margin-bottom: 15px;
  line-height: 1.6;
}

.terms-container .info-pp li {
  list-style-type: square;
  text-align: left;
}
