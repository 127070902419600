.wishlist-container {
  margin: 50px;
  padding: 0px 100px;
}
.wishlist-product-items {
  margin: 30px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.wishlist-product-card {
  width: 350px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.wishlist-product-image img {
  width: 100%;
  padding: 15px;
  border-radius: 30px;
  height: 300px;
}

.wishlist-product-info {
  padding: 0px 20px;
}

.wishlist-product-info h2 {
  margin-top: 0;
  font-size: 25px;
}

.wishlist-product-description {
  color: #666;
  margin: 0;
}

.wishlist-product-icons {
  color: rgb(241, 202, 28);
  margin-bottom: 10px;
}

.wishlist-product-card-btn {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
}
.wishlist-add-to-cart {
  background-color: #ffc35b;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
  cursor: pointer;
  width: 70%;
  transition: background-color 0.3s;
}

.wishlist-add-to-cart:hover {
  background-color: #e5950b;
}
.wishlist-add-to-wishlist {
  background-color: #ffc35b;
  color: rgb(190, 9, 9);
  border: none;
  border-radius: 10px;
  width: auto;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.wishlist-add-to-wishlist:hover {
  background-color: #e5950b;
}

.wishlist-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wishlist-clear-btn {
  background-color: #ffc35b;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 20px;
  cursor: pointer;
}
.wishlist-clear-btn:hover {
  background-color: #e5950b;
}

.empty-wishlist {
  margin: 0 auto;
  display: flex;
  padding: 50px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1245px) {
  .wishlist-container {
    padding: 0;
  }
  .wishlist-product-card {
    width: auto;
  }
}
@media screen and (max-width: 910px) {
  .wishlist-add-to-cart {
    padding: 5px 8px;
  }
  .wishlist-container {
    padding: 0px 0px;
    min-height: 100vh;
  }
}
@media screen and (max-width: 910px) {
  .wishlist-product-items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 510px) {
  .wishlist-product-items {
    grid-template-columns: repeat(1, 1fr);
  }
  .wishlist-clear-btn {
    padding: 5px 10px;
  }
}
@media screen and (max-width: 390px) {
  .wishlist-product-items {
    gap: 10px;
  }
}
