.checkout-page {
  background: url(../../images/Group\ 133.png);
  background-color: #fcfaea;
  background-position: center;
}
.checkout-page h1 {
  padding: 20px 100px;
  font-weight: bold;
}
.order-summary {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0px 100px;
  margin-bottom: 80px;
  border-radius: 20px;
  padding: 30px 0px;
  backdrop-filter: blur(30px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}

.order-item-container {
  border-right: 2px solid rgb(169, 169, 169);
  padding-right: 50px;
}
.order-item-container h3 {
  margin-bottom: 20px;
  margin-left: 20px;
  font-weight: 600;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.order-item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 0;
  width: 600px;
  border-bottom: 2px solid rgb(169, 169, 169);
}

.order-item-container button {
  margin: 20px 50px;
  background: none;
  border: none;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.order-item img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
.order-item h4 {
  width: 200px;
  font-size: 18px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.order-item-price table {
  border-collapse: collapse;
  margin: auto;
  font-size: 22px;
}

.order-item-price td {
  padding: 15px 50px;
}

.order-item-price .bold {
  font-weight: bold;
}

.order-item-price table,
td {
  border: none;
}

.order-item-price table tr:last-of-type {
  border-top: 2px solid rgb(169, 169, 169);
}

.order-item-price button {
  background: #a7bb00;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  color: rgb(255, 255, 255);
  padding: 5px 50px;
}
/* dilevery address section */
.shipping-address-form {
  width: 700px;
  margin-bottom: 80px;
  border-radius: 20px;
  padding: 50px 50px;
  backdrop-filter: blur(30px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}
.shipping-address-form h3 {
  margin-bottom: 20px;
  font-weight: 600;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.shipping-form {
  padding: 0 0px;
}
.shipping-form #save-btn {
  display: flex;
  justify-content: center;
}
.shipping-form button {
  padding: 5px 30px;
  border: none;
  font-size: 20px;
  background-color: #ffc35b;
  border-radius: 8px;
}
.shipping-form input,
.additional-address {
  width: 100%;
  background: #fcfaea;
  padding: 18px 12px;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-bottom: 15px;

  box-shadow: inset 0 2px 2px rgba(87, 87, 87, 0.5);
}

.additional-address {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  font-size: 20px;
}
#radio {
  background: none;
  box-shadow: none;
  border: none;
  width: 25px;
  height: 25px;
}

#add-info {
  display: flex;
  gap: 10px;
}

#save-checkbox {
  display: flex;
  margin: 0px;
}

#save-checkbox input[type="checkbox"] {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

#save-checkbox label {
  font-size: 18px;
  color: #000000;
}

#checkout-btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
#checkout-btn button {
  background: #86bb00;
  border: none;
  border-radius: 5px;
  width: 30%;
  margin-bottom: 50px;
  color: white;
  padding: 15px 0;
  font-size: 25px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
}

.error {
  color: red;
  font-size: 15px;
}
input.error-border {
  border: 2px solid red;
}

.container-2 {
  display: flex;
  align-items: self-start;
  justify-content: center;
  gap: 50px;
}

.coupon-container {
  backdrop-filter: blur(20px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 30px 50px;
  border-radius: 20px;
  margin-top: 50px;
  width: auto;
}

.coupon-box {
  display: block;
}

.coupon-box form input {
  margin: 20px 0;
  width: 100%;
  background: #ffffff;
  padding: 10px;
  border: 1px solid black;
  outline: none;
  border-radius: 8px;
  margin-bottom: 15px;
}

.coupon-box button {
  padding: 5px 30px;
  border: none;
  font-size: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #ffc35b;
  border-radius: 50px;
}

.payment-method-container {
  backdrop-filter: blur(20px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 30px 50px;
  border-radius: 20px;
  width: auto;
}

.payment-selection-page h2 {
  font-weight: bold;
  padding: 30px 50px;
}

.payment-methods {
  margin-top: 30px;
}

#cash,
#razorpay {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

#cash input,
#razorpay input {
  height: 20px;
  width: 20px;
}

#cash label,
#razorpay label {
  font-size: 25px;
}

@media screen and (max-width: 1250px) {
  .order-summary {
    padding: 30px 10px;
  }

  .order-item-container {
    padding-right: 20px;
  }

  .order-item {
    width: auto;
  }
  .order-item h4 {
    margin-left: 20px;
  }

  .order-item-price td {
    padding: 15px 20px;
  }
  .shipping-address-form {
    width: auto;
    height: auto;
  }
}
@media screen and (max-width: 975px) {
  .order-item-price table tr td {
    font-size: 18px;
  }
  .order-summary {
    margin: 20px;
  }
  .container-2 {
    display: block;
    margin: 50px;
  }
  #checkout-btn button {
    width: auto;
    padding: 10px 50px;
  }
}
@media screen and (max-width: 860px) {
}
@media screen and (max-width: 675px) {
  .order-summary {
    display: block;
  }
  .order-item p {
    font-size: 16px;
  }
}
@media screen and (max-width: 550px) {
  .shipping-form #fullname {
    display: block;
  }
  #add-info {
    display: block;
  }
  .shipping-address-form {
    padding: 20px 20px;
  }
  .container-2 {
    margin: 20px;
  }
}
@media screen and (max-width: 380px) {
  .additional-address p {
    font-size: 16px;
    word-wrap: break-word;
  }
}
