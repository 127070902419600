.img-container {
  position: relative;
  width: 100%;
}

.img-background {
  position: relative;
  width: 100%;
  height: 600px;
  background-image: url("../../images/Rectangle\ 23.png");
  background-size: cover;
  background-position: center;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  background: linear-gradient(
    270deg,
    rgba(249, 219, 189, 0.8) 0%,
    rgba(234, 219, 207, 0.22) 100%
  );
}
@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0; /* Start off-screen to the left and invisible */
  }
  100% {
    transform: translateX(0);
    opacity: 1; /* Slide in to the original position and become visible */
  }
}
.about-intro {
  position: absolute;
  top: 5%;
  left: 40%;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 20px;
  font-weight: bold;
  animation: slideIn 2s ease forwards; /* Apply animation */
}
.about-intro p {
  line-height: 40px;
  font-size: 25px;
}
.about-intro h1,
#border {
  font-weight: bold;
  font-size: 50px;
}
.about-intro #border {
  width: 500px;
  height: 40px;
  padding-top: 0px;
}
.heading img {
  display: block;
  margin: 20px auto;
  width: 350px;
}

.about-text {
  font-size: 1.1rem;
  color: #000000;
  max-width: 75%;
  margin: auto;
}

.vision {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 550px;
  margin: 80px 80px;
}

.vision .pattern-img {
  position: relative;
  top: 10%;
  left: 0%;
  height: 900px;
  width: 900px;
  z-index: 1;
  transform: scaleX(-1);
}
.vision .mandala {
  position: absolute;
  width: 650px;
  left: 5%;
}
.vision .circle-img {
  border-radius: 50%;
  background: url(../../images/Clip\ path\ group.png);
  background-size: 100%;
  height: 500px;
  margin-left: 60px;
  z-index: 1;
  position: absolute;
}

/* part2 */
.values {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  height: 550px;
  margin: 0px 80px;
}
.values .circle-img {
  border-radius: 50%;
  height: 500px;
  margin-right: 60px;
  position: absolute;
  z-index: 1;
}

.values .mandala {
  position: absolute;
  width: 650px;
  right: 5%;
}

.values .pattern-img {
  position: relative;
  top: 10%;
  right: 2%;
  height: 900px;
  width: 900px;
  z-index: 1;
}

/* part3 */

.family {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 550px;
  margin: 80px 80px;
}
.family .circle-img {
  border-radius: 50%;
  height: 500px;
  margin-left: 60px;
  position: absolute;
  z-index: 1;
}

.family .mandala {
  position: absolute;
  width: 650px;
  left: 5%;
}

.family .pattern-img {
  position: relative;
  top: 10%;
  left: 0;
  height: 900px;
  width: 900px;
  transform: scaleX(-1);
  z-index: 1;
}
.family .circle-img,
.values .circle-img,
.vision .circle-img {
  transition: transform 0.6s ease-in-out;
  cursor: pointer;
}

.family .circle-img:hover,
.values .circle-img:hover,
.vision .circle-img:hover {
  transform: scale(1.05);
}

.text h1 {
  font: bolder 3em "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  color: #000000;
  text-align: center;
}
.text p {
  background: #f0efe5;
  color: #3b5f00;
  height: 400px;
  font-size: 25px;
  width: 500px;
  padding: 40px;
  margin: 20 50px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 29px 0px;
}

/* ----------------------------------stories container--------------------------------- */

/* customer reviews*/
.stories-container {
  display: flex;
  justify-content: center;
}
.story {
  padding: 40px;
  width: 600px;
  background: #fff7e9;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 29px 0px;
  border-radius: 30px;
  font-size: 20px;
}
.story img {
  width: 120px;
}
.customer-name {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.customer-name h4 {
  margin-left: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.8em;
  font-weight: 600;
}
.customer-slider {
  width: 100%;
}
.customer-slider input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}
.customer-testimonials {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 400px;
  overflow: hidden;
}
.customer-testimonials .item {
  padding: 0 20px;
  position: absolute;
  top: 0;
  box-sizing: border-box;
  transition: transform 0.4s;
  user-select: none;
  cursor: pointer;
}
.card {
  border-radius: 20px;
}
.customer-testimonials .item img {
  padding: 20px;
}
.dots {
  display: flex;
  justify-content: center;
}
.dots label {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #413b52;
  margin: 7px;
  transition-duration: 0.2s;
}
#t-1:checked ~ .dots label[for="t-1"],
#t-2:checked ~ .dots label[for="t-2"],
#t-3:checked ~ .dots label[for="t-3"],
#t-4:checked ~ .dots label[for="t-4"],
#t-5:checked ~ .dots label[for="t-5"] {
  transform: scale(2);
  background-color: #fff;
  box-shadow: 0px 0px 0px 3px #dddddd24;
}

#t-1:checked ~ .dots label[for="t-2"],
#t-2:checked ~ .dots label[for="t-1"],
#t-2:checked ~ .dots label[for="t-3"],
#t-3:checked ~ .dots label[for="t-2"],
#t-3:checked ~ .dots label[for="t-4"],
#t-4:checked ~ .dots label[for="t-3"],
#t-4:checked ~ .dots label[for="t-5"],
#t-5:checked ~ .dots label[for="t-4"] {
  transform: scale(1.5);
}

#t-1:checked ~ .customer-testimonials label[for="t-3"],
#t-2:checked ~ .customer-testimonials label[for="t-4"],
#t-3:checked ~ .customer-testimonials label[for="t-5"],
#t-4:checked ~ .customer-testimonials label[for="t-1"],
#t-5:checked ~ .customer-testimonials label[for="t-2"] {
  transform: translate3d(600px, 0, -180px);
  z-index: 2;
}

#t-1:checked ~ .customer-testimonials label[for="t-2"],
#t-2:checked ~ .customer-testimonials label[for="t-3"],
#t-3:checked ~ .customer-testimonials label[for="t-4"],
#t-4:checked ~ .customer-testimonials label[for="t-5"],
#t-5:checked ~ .customer-testimonials label[for="t-1"] {
  transform: translate3d(400px, 0, -90px);
  z-index: 3;
}

#t-2:checked ~ .customer-testimonials label[for="t-1"],
#t-3:checked ~ .customer-testimonials label[for="t-2"],
#t-4:checked ~ .customer-testimonials label[for="t-3"],
#t-5:checked ~ .customer-testimonials label[for="t-4"],
#t-1:checked ~ .customer-testimonials label[for="t-5"] {
  transform: translate3d(-400px, 0, -90px);
  z-index: 3;
}

#t-3:checked ~ .customer-testimonials label[for="t-1"],
#t-4:checked ~ .customer-testimonials label[for="t-2"],
#t-5:checked ~ .customer-testimonials label[for="t-3"],
#t-2:checked ~ .customer-testimonials label[for="t-5"],
#t-1:checked ~ .customer-testimonials label[for="t-4"] {
  transform: translate3d(-600px, 0, -180px);
}

#t-1:checked ~ .customer-testimonials label[for="t-1"],
#t-2:checked ~ .customer-testimonials label[for="t-2"],
#t-3:checked ~ .customer-testimonials label[for="t-3"],
#t-4:checked ~ .customer-testimonials label[for="t-4"],
#t-5:checked ~ .customer-testimonials label[for="t-4"],
#t-5:checked ~ .customer-testimonials label[for="t-5"] {
  z-index: 4;
}

.cart,
.wishlist {
  background: #ffc358;
  border: none;
  border-radius: 10px;
  height: 60px;
  padding: 10px 20px;
  margin-top: 10px;
}
.cart {
  margin-right: 10px;
  width: 60%;
}
.wishlist {
  width: 80px;
  color: white;
}

.cart:hover,
.wishlist:hover {
  background: #e8ab42;
}
.card-content {
  padding-top: 0px;
  padding-left: 20px;
}
.card-content h5 {
  text-align: left;
  font-weight: bold;
}

/* ------------------------------------responsiveness------------------------------------ */
@media screen and (max-width: 1300px) {
  .vision .mandala,
  .family .mandala {
    height: 550px;
    width: 550px;
    margin-left: 30px;
  }
  .values .mandala {
    height: 550px;
    width: 550px;
  }
  .values .circle-img,
  .vision .circle-img,
  .family .circle-img {
    height: 450px;
  }
  .values .circle-img {
    margin-right: 30px;
  }
  .text p {
    height: auto;
    width: auto;
  }
}
@media screen and (max-width: 1205px) {
  .about-intro {
    padding: 10px;
  }

  .about-text {
    font-size: 1rem;
  }

  .text p {
    height: auto;
    width: auto;
  }
}
@media screen and (max-width: 1110px) {
  .text p {
    font-size: 20px;
  }
}

@media screen and (max-width: 1025px) {
  .vision,
  .values,
  .family {
    margin: 0;
  }
  .vision .mandala,
  .family .mandala {
    height: 500px;
    width: 500px;
    margin-left: 0px;
    left: 0;
  }
  .values .mandala {
    height: 500px;
    width: 500px;
    margin-right: 0;
    right: 0;
  }
  .values .circle-img,
  .vision .circle-img,
  .family .circle-img {
    height: 400px;
    margin-left: 45px;
  }
  .values .circle-img {
    margin-right: 45px;
  }
  .text p {
    margin: 0;
    height: 400px;
    width: 400px;
  }
  .text h1 {
    font-size: 35px;
    margin: 0;
  }
  .text #border {
    width: 170px;
  }
}
@media screen and (max-width: 980px) {
  .about-intro h1,
  #border {
    right: 20%;
  }
  .about-intro {
    left: 10%;
  }
  .values,
  .vision,
  .family {
    margin: 20px 0px;
  }
  .vision .pattern-img,
  .family .pattern-img,
  .values .pattern-img {
    height: 700px;
  }
  .vision .mandala,
  .family .mandala {
    height: 400px;
    width: 400px;
    margin-left: 0px;
    left: 0;
  }
  .values .mandala {
    height: 400px;
    width: 400px;
    margin-right: 0;
    right: 0;
  }
  .values .circle-img,
  .vision .circle-img,
  .family .circle-img {
    height: 300px;
  }
  .vision .circle-img,
  .family .circle-img {
    margin-left: 40px;
  }
  .values .circle-img {
    margin-right: 50px;
  }
  .text p {
    width: auto;
    height: auto;
    font-size: 18px;
    margin: 0 80px 0 0;
    text-align: center;
    height: 300px;
  }
}
@media screen and (max-width: 900px) {
  .text p {
    padding: 10px;
  }
  .story {
    width: 500px;
  }
}
@media screen and (max-width: 838px) {
  .text p {
    padding: 20px;
    height: auto;
  }
}
@media screen and (max-width: 800px) {
  .values,
  .vision,
  .family {
    display: flex;
    height: auto;
    background-size: 80%;
    background-position: bottom;
    flex-direction: column-reverse;
  }
  .vision .pattern-img,
  .values .pattern-img,
  .family .pattern-img {
    width: 600px;
  }
  .values .circle-img,
  .vision .circle-img,
  .family .circle-img {
    height: 400px;
  }
  .values .mandala,
  .vision .mandala,
  .family .mandala {
    margin: 7% 18%;
    height: 500px;
    width: 500px;
  }
  .vision .circle-img,
  .family .circle-img {
    margin: 13% 28%;
  }
  .values .circle-img {
    margin: 13% 28%;
  }
  .text p {
    height: auto;
    font-size: 18px;
    margin: 50px;
    padding: 50px;
  }
}
@media screen and (max-width: 730px) {
  .values .circle-img {
    margin: 100px 220px;
  }
  .values .mandala {
    margin: 50px 70px;
  }
}

@media screen and (max-width: 720px) {
  .about-intro h1 {
    font-size: 30px;
  }
  .about-intro p {
    font-size: 20px;
  }
  .values .circle-img,
  .vision .circle-img,
  .family .circle-img {
    margin-left: 250px;
  }
  .customer-testimonials .item {
    padding: 40px;
  }
  .dots {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .story {
    width: 500px;
    height: 300px;
    padding: 20px;
  }
}

@media screen and (max-width: 610px) {
  .img-background {
    background-position: left;
  }
  .vision .pattern-img,
  .values .pattern-img,
  .family .pattern-img {
    width: 80%;
  }
  .values .circle-img,
  .vision .circle-img,
  .family .circle-img {
    margin: 80px auto;
  }
  .values .mandala,
  .vision .mandala,
  .family .mandala {
    margin: 40px 50px;
  }
  .story {
    width: 450px;
    height: 250px;
    padding: 10px;
    font-size: 18px;
  }
  .customer-name {
    margin: 0;
    padding: 0;
  }
}
@media screen and (max-width: 580px) {
  .about-intro p {
    line-height: 35px;
    font-size: 18px;
  }
  .about-intro h1,
  #border {
    right: 0%;
  }
  .heading #border {
    width: 290px;
    height: 30px;
  }
  .vision .pattern-img,
  .values .pattern-img,
  .family .pattern-img {
    width: 80%;
  }
  .values .circle-img,
  .vision .circle-img,
  .family .circle-img {
    margin: 150px auto;
    height: 340px;
  }
  .values .mandala,
  .vision .mandala,
  .family .mandala {
    margin: 120px 80px;
    height: 400px;
    width: 400px;
  }
  .story {
    font-size: 16px;
    width: 300px;
  }
  #t-1:checked ~ .customer-testimonials label[for="t-3"],
  #t-2:checked ~ .customer-testimonials label[for="t-4"],
  #t-3:checked ~ .customer-testimonials label[for="t-5"],
  #t-4:checked ~ .customer-testimonials label[for="t-1"],
  #t-5:checked ~ .customer-testimonials label[for="t-2"] {
    transform: translate3d(400px, 0, -180px);
    z-index: 2;
  }

  #t-1:checked ~ .customer-testimonials label[for="t-2"],
  #t-2:checked ~ .customer-testimonials label[for="t-3"],
  #t-3:checked ~ .customer-testimonials label[for="t-4"],
  #t-4:checked ~ .customer-testimonials label[for="t-5"],
  #t-5:checked ~ .customer-testimonials label[for="t-1"] {
    transform: translate3d(200px, 0, -90px);
    z-index: 3;
  }

  #t-2:checked ~ .customer-testimonials label[for="t-1"],
  #t-3:checked ~ .customer-testimonials label[for="t-2"],
  #t-4:checked ~ .customer-testimonials label[for="t-3"],
  #t-5:checked ~ .customer-testimonials label[for="t-4"],
  #t-1:checked ~ .customer-testimonials label[for="t-5"] {
    transform: translate3d(-200px, 0, -90px);
    z-index: 3;
  }

  #t-3:checked ~ .customer-testimonials label[for="t-1"],
  #t-4:checked ~ .customer-testimonials label[for="t-2"],
  #t-5:checked ~ .customer-testimonials label[for="t-3"],
  #t-2:checked ~ .customer-testimonials label[for="t-5"],
  #t-1:checked ~ .customer-testimonials label[for="t-4"] {
    transform: translate3d(-400px, 0, -180px);
  }
}

@media screen and (max-width: 555px) {
  .customer-slider {
    margin: auto;
  }
  .customer-testimonials {
    perspective: 400px;
  }
  .customer-testimonials .item {
    padding: 60px;
  }
  .vision .pattern-img,
  .values .pattern-img,
  .family .pattern-img {
    width: 80%;
  }
  .values .circle-img,
  .vision .circle-img,
  .family .circle-img {
    margin: 150px auto;
    height: 340px;
  }
  .values .mandala,
  .vision .mandala,
  .family .mandala {
    margin: 120px 60px;
    height: 400px;
    width: 400px;
  }
  .text p {
    padding: 20px;
  }
}

@media screen and (max-width: 480px) {
  .vision .pattern-img,
  .values .pattern-img,
  .family .pattern-img {
    width: 80%;
  }
  .values .circle-img,
  .vision .circle-img,
  .family .circle-img {
    margin: 150px auto;
    height: 340px;
  }
  .values .mandala,
  .vision .mandala,
  .family .mandala {
    margin: 120px 40px;
    height: 400px;
    width: 400px;
  }
}
@media screen and (max-width: 450px) {
  .vision .pattern-img,
  .values .pattern-img,
  .family .pattern-img {
    width: 80%;
    margin: 0;
  }
  .values .circle-img,
  .vision .circle-img,
  .family .circle-img {
    margin: 190px auto;
    height: 340px;
  }
  .values .mandala,
  .vision .mandala,
  .family .mandala {
    margin: 150px 10px;
    height: 420px;
    width: 420px;
  }
}
@media screen and (max-width: 430px) {
  .about-intro {
    left: 0%;
  }
  .about-intro p {
    font-size: 18px;
  }
  .about-intro h1 {
    font: 1.3em sans-serif;
    font-weight: bold;
    left: 0;
  }
  .about-intro h1,
  #border {
    right: 0%;
    left: 0%;
  }
  .vision .pattern-img,
  .values .pattern-img,
  .family .pattern-img {
    width: 80%;
    margin: 0;
  }
  .values .circle-img,
  .vision .circle-img,
  .family .circle-img {
    margin: 250px auto;
    height: 280px;
  }
  .values .mandala,
  .vision .mandala,
  .family .mandala {
    margin: 220px 40px;
    height: 350px;
    width: 350px;
  }
}
@media screen and (max-width: 400px) {
  .text p {
    margin-bottom: 400px;
  }
  .vision .pattern-img,
  .values .pattern-img,
  .family .pattern-img {
    display: none;
  }
  .values .circle-img,
  .vision .circle-img,
  .family .circle-img {
    margin: 0px auto;
    margin-bottom: 50px;
    height: 280px;
  }
  .values .mandala,
  .vision .mandala,
  .family .mandala {
    margin: 0% 20px;
    margin-bottom: 20px;
    height: 350px;
    width: 350px;
  }
}
@media screen and (max-width: 370px) {
  .vision .pattern-img,
  .values .pattern-img,
  .family .pattern-img {
    display: none;
  }
  .values .circle-img,
  .vision .circle-img,
  .family .circle-img {
    margin: 0px auto;
    margin-bottom: 50px;
    height: 230px;
  }
  .values .mandala,
  .vision .mandala,
  .family .mandala {
    margin: 0% 30px;
    margin-bottom: 20px;
    height: 300px;
    width: 300px;
  }
}
@media screen and (max-width: 350px) {
  .about-intro {
    padding: 0px;
    margin: 0;
  }
  .about-intro #border {
    width: 230px;
  }
  .values .mandala,
  .vision .mandala,
  .family .mandala {
    margin: 0% 10px;
    margin-bottom: 20px;
    height: 300px;
    width: 300px;
  }
  .text p {
    padding: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
