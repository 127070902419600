.mySwiper {
  background: #f5f5f5;
  display: flex;
  justify-content: center;
}

.swiper-slide {
  width: auto;
  height: auto;
  margin: 20px;
}

.swiper-slide-image {
  width: 300px;
  height: 230px;
  box-shadow: none;
  padding-top: 20px;
  border-radius: 30px;
  padding-bottom: 20px;
}

.swiper-slide.swiper-slide-active .swiper-slide-image {
  width: 350px;
  border-radius: 30px;
  height: 250px;
  padding: 10px;
}

.new-card-body {
  display: none;
}
.swiper-slide.swiper-slide-active .new-card-body {
  display: block;
  width: 350px;
  padding: 20px;
}
.new-card-body {
  border: none;
  width: auto;
}
.new-card-body h3 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.new-card-body p {
  text-align: center;
  font-size: 15px;
}
.price {
  display: flex;
  justify-content: space-between;
}
.price p:first-child {
  color: rgb(100, 100, 100);
  text-decoration: line-through;
  margin-right: 10px;
  font-size: 18px;
}
.price p {
  color: black;
  font-size: 25px;
}
.new-card-btn {
  border: none;
  outline: none;
  background: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
}

/* buttons for navigation */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-next {
  right: 10px;
}

.swiper-button-next::before {
  transform: rotate(135deg);
}

.swiper-button-prev::before {
  transform: rotate(-50deg);
}
